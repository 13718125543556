.passcard-table {
  display: flex;
  overflow: auto;
  .editable-cell-editing {
    .MuiInputBase-input {
      padding: 2.5px 14px;
    }
  }

  &__passcard-url {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .copy__children {
      width: 100%;
      overflow: hidden;
    }

    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__copy {
    width: 100%;

    .copy__children {
      display: flex;
      align-items: center;
    }
  }
}

.passcard-placeholder-table {
  width: 400px;
}
