.directory {
  display: flex;

  &__name {
    margin: 0 5px;
  }

  &__actions {
    vertical-align: middle;
    height: 16px;
    button {
      padding: 0;
    }
  }

  &:hover {
    .directory__actions {
      display: inline-flex;
    }
  }

  &__content {
    display: flex;
    .directory__actions {
      display: none;
    }
  }

  &--selected {
    padding: 3px;
    font-weight: 500;
    font-size: 1.1em;
  }
}
