@use "../../../Feature/styles/colors.scss" as colors;
@use "../../../Feature/styles/mixins.scss" as mixins;

.user-group-details {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;

  &__title {
    width: calc(100% - 10px);
    font-size: 1.2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
    margin-top: 0;
    padding: 5px;
  }

  &__content {
    display: flex;
    margin-left: 10px;
    overflow: auto;
    padding: 2px 2px;
    flex-wrap: wrap;
  }

  &__user-transfer-list {
    margin-right: 10px;
    margin-bottom: 10px;
    flex: 1 1 0;
    max-height: calc(100% - 10px);
    // overflow: auto;
    .MuiGrid-root {
      flex-wrap: unset;
    }

    .transfer-list__right-column,
    .transfer-list__left-column {
      flex: auto;

      .MuiListItemText-primary {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 300px;
      }
    }
  }

  &__directories {
    margin-right: 10px;
    margin-bottom: 10px;
    height: calc(100% - 10px);
    flex: 1 1 0;

    .MuiCard-root {
      height: 100%;
    }
    .MuiCardContent-root {
      padding-top: unset;
      padding-bottom: 16px !important;
      height: calc(100% - 2 * 16px);
    }

    .MuiCardHeader-root {
      border-bottom: 1px solid colors.$border-color;
      .MuiCardHeader-title {
        font-size: 1rem;
      }
    }
    .directory-permission-relation-list {
      height: calc(100% - 32px);
    }
  }
}
