.user-storage-name {
  display: flex;
  vertical-align: middle;
  svg {
    vertical-align: middle;
    margin: 0 5px;
  }

  &__name {
    margin: 0 5px;
  }

  &__content {
    vertical-align: middle;
  }

  &--selected {
    padding: 3px;
    font-weight: 500;
    font-size: 1.1em;
  }
}
