$field-value-color: #1976d2;

.user-card-list-item {
  display: flex;
  margin-right: unset !important;
  margin-bottom: unset !important;
  max-width: 405px;
  position: relative;

  &__field {
    white-space: nowrap;
    &-title {
      white-space: nowrap;
      margin-right: 8px;
    }

    &-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .MuiCheckbox-root {
        padding: unset;
      }
    }
  }

  &__header {
    display: flex;
    width: 100%;
  }

  &__actions {
    margin-left: auto;
    padding-left: 5px;
    white-space: nowrap;

    button {
      padding: unset;
    }
  }

  &__create {
    height: 137.5px;
  }

  .groupped-item {
    margin-right: 8px;
  }

  &__user-full-name {
    font-weight: bold;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .MuiCardHeader-root {
    padding-top: 16px;
    display: block !important;
  }
  .MuiCardContent-root {
    width: 100%;
    max-width: calc(100% - 72px);
    &:last-child {
      padding-bottom: 8px !important;
    }
  }
  .MuiChip-root {
    height: 21px;
  }

  .MuiCardHeader-avatar {
    margin-right: unset;
  }
}
