.profile-page {
  display: flex;
  flex: 1 1 0;
  max-width: 1300px;
  justify-content: center;
  margin: 0 auto;
  padding-top: 5px;

  &__wrapper {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }

  &__change-password-button {
    margin-top: 8px !important;
  }
}
