.user-table-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  &__top-panel {
    justify-content: center;
    & > * {
      margin-bottom: 10px !important;
    }
  }

  &__full-name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__blocked-checkbox {
    display: flex;
    justify-content: center;
  }
}
