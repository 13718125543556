.directory-update {
  display: flex;

  &__name {
    margin: 0 5px;
  }

  &__content {
    .MuiTextField-root .MuiInputBase-input {
      padding: 2px 14px;
      min-width: 120px;
    }

    .loader {
      margin-left: 5px;
    }
  }
}
