.user-list {
  &__list-format {
    position: fixed;
    right: 25px;
    top: 70px;
    z-index: 1;
    background-color: white;
    hr {
      height: 30px;
      align-self: center;
    }
  }
  &--empty {
    text-align: center;
    font-size: 1.2rem;
    padding-top: 10px;
  }
}
