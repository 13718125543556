.user-group-list {
  &__add {
    margin-bottom: 10px;
  }

  .user-group-edit-item {
    .MuiInputBase-input {
      padding: 2px 14px;
    }
  }
}
