@use "../../styles/colors.scss" as colors;

$toggleIconWidth: 24px;
$childrenMarginLeft: 0;

.directory-tree-item {
  .directory {
    cursor: pointer;
  }

  &__children {
    margin-left: 34px;
    border-left: gray dashed;

    .directory-create {
      margin-left: $toggleIconWidth + $childrenMarginLeft;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;

    &--no-children {
      margin-left: $toggleIconWidth;
    }
  }

  &__toggle-button {
    cursor: pointer;
  }
}

.directory-tree-item .directory-tree-item {
  margin-left: $childrenMarginLeft;
}

.dragging {
  position: absolute;
  z-index: 1000;
}

.draggable-from {
  opacity: 0.5;
}

.draggable-hover,
.droppable-hover {
  background-color: colors.$draggable-hover-color;
}
