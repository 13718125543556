.user-group-list-item {
  display: flex;

  &__actions {
    display: none;
    vertical-align: middle;
    height: 16px;
    button {
      padding: 0;
    }
  }

  &--selected {
    background-color: gainsboro;
  }

  &:hover {
    .user-group-list-item__actions {
      display: flex;
    }
  }
}
