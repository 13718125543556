.view-password {
  display: flex;
  width: 100%;

  &__text {
    align-self: center;
    margin-right: 5px;
  }

  button {
    margin-left: auto;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
  }
}
