.general-page {
  display: flex;
  flex-direction: column;
  height: calc(100% - 5px);
  padding-bottom: 5px;

  &__content {
    padding: 0 5px;
    display: flex;
    flex: 1 1 0;
    overflow: auto;
  }
}
