.user-account {
  &__user-first-name {
    font-size: 1rem !important;
    text-transform: none !important;
    margin-right: 5px;
    font-weight: initial !important;
  }

  &__profile-link {
    a {
      color: inherit !important;
      text-decoration: auto !important;
    }
  }
}
