body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

#root {
  height: 100vh;
  overflow: auto;
}

.MuiCard-root {
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 10%) !important;
}

.text-align-center {
  text-align: center;
}

.user-select-none {
  user-select: none !important;
}
