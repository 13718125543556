.export-page {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;

    & > button {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: initial;
      }
    }
  }
}
