.user-group-page {
  display: flex;
  flex: 1 1 0;
  overflow: auto;

  &__content {
    flex: 1 1 0;
    overflow: auto;
  }
}
