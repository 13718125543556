@use "../../../Feature/styles/colors.scss" as colors;

.select-list {
  &--invalid {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: rgb(211, 47, 47) !important;
    }

    label.MuiInputLabel-root {
      color: rgb(211, 47, 47);
    }
  }
}

.MuiAutocomplete-popper .MuiAutocomplete-paper .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"] {
  background-color: colors.$color-selected !important;
}
