.registration-page {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
  align-items: center;

  .MuiPaper-root:first-child {
    margin-top: 15vh;
    margin-bottom: 10px;
  }

  .MuiPaper-root {
    width: 450px;
    height: fit-content;
    box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 10%);
  }
}
