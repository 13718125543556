.user-card-list {
  display: flex;
  flex: 1 1 0;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
  align-content: flex-start;
  overflow: auto;

  .MuiGrid-root {
    width: 100% !important;
  }
}
