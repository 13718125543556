.import-page {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;

    & > .MuiTextField-root {
      margin-bottom: 10px;
    }
  }
}
