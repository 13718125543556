$field-value-color: #1976d2;

.user-card-list-item-create {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 137.5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }

  &__content {
    text-align: center;
  }

  .MuiSvgIcon-root {
    display: flex;
    margin: 0 auto;
  }
}
