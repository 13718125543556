.restore-page {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
  align-items: center;

  .MuiPaper-root {
    width: 100%;
    max-width: 800px;
    height: fit-content;
    box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 10%);
    margin-bottom: 10px;

    &:first-child {
      margin-top: 25vh;
    }
  }
}
