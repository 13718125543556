@use "../../../Feature/styles/colors.scss" as colors;

.permission-toggle-button {
  button {
    border-color: colors.$primary-color;

    &.Mui-selected {
      background-color: colors.$primary-light-color;
    }
  }
}
