@use "../../styles/colors.scss" as colors;
$MARGIN_LEFT_INDENT: 15px;

.directory-permission-relation-list {
  height: 100%;
  overflow: auto;

  &-item {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  &--striped {
    .directory-permission-relation-list-item:nth-child(odd) {
      background-color: colors.$color-striped;
    }
  }

  &-item__title--indent-0 {
    margin-left: $MARGIN_LEFT_INDENT * 0;
  }
  &-item__title--indent-1 {
    margin-left: $MARGIN_LEFT_INDENT * 1;
  }
  &-item__title--indent-2 {
    margin-left: $MARGIN_LEFT_INDENT * 2;
  }
  &-item__title--indent-3 {
    margin-left: $MARGIN_LEFT_INDENT * 3;
  }
  &-item__title--indent-4 {
    margin-left: $MARGIN_LEFT_INDENT * 4;
  }
  &-item__title--indent-5 {
    margin-left: $MARGIN_LEFT_INDENT * 5;
  }
}
