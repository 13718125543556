.favorites {
  cursor: pointer;
  margin-left: 24px;

  svg {
    vertical-align: middle;
  }

  &__name {
    margin: 0 5px;
  }

  &__content {
    // display: flex;
    vertical-align: middle;
  }

  &--selected {
    padding: 3px;
    font-weight: 500;
    font-size: 1.1em;
  }
}
