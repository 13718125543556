$border: 1px solid black;
@use "../../../../Feature/styles/colors.scss" as colors;

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  & .cell {
    padding: 0 5px;
    border: $border;
    word-break: break-word;
    min-width: 150px;
    width: 150px;
  }

  & .body .row {
    &:hover {
      .cell {
        background-color: colors.$color-hover !important;
      }
    }
  }

  &__container {
    padding-bottom: 1px;
    max-width: 100%;
  }

  &--striped {
    .body .row:nth-child(odd) {
      background-color: colors.$color-striped;
    }
  }

  &--separate-border {
    border-collapse: separate;
  }

  &--no-border {
    .cell {
      border: unset;
    }
  }

  &--cell-shadow {
    .head {
      .row:first-child {
        .cell {
          border-top: 1px solid rgba(0, 0, 0, 0.25);
        }
      }
    }
    .cell {
      box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
    }
  }
}
