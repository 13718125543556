.transfer-list {
  height: 100%;
  padding: 2px;
  margin-top: unset !important;

  &__list {
    height: calc(100% - 58px);
    overflow: auto;
    padding: unset !important;

    & > .MuiGrid-root {
      flex-wrap: unset;
    }
  }

  &__middle-column {
    display: flex;
    align-items: center;
  }

  .MuiPaper-root {
    height: 100%;
  }

  &__left-column,
  &__right-column,
  &__middle-column {
    padding-top: unset !important;
  }
}
