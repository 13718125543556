.user-storage {
  margin-left: 24px;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }

  &__name {
    margin: 0 5px;
    .loader {
      vertical-align: middle;
    }
  }

  &__content {
    vertical-align: middle;
  }

  &--selected {
    padding: 3px;
    font-weight: 500;
    font-size: 1.1em;
  }
}
