.storage-list {
  display: flex;
  flex: 1 1 0;

  &__content {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    margin-left: 10px;
  }

  &__title {
    display: flex;
    width: calc(100% - 10px);
    font-size: 1.2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
    margin-top: 0;
    padding: 5px;
    margin-left: -10px;

    .user-storage-name {
      font-size: 1.3rem;
      font-weight: bold;
      padding-left: 5px;
      cursor: inherit !important;
      svg {
        font-size: 1.7rem;
      }
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
  }

  &__top-panel {
    margin-bottom: 10px;
  }

  &__sidebar {
    width: 350px;
    min-width: 300px;
    max-width: 400px;

    & .sidebar {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
    }

    .directory__content {
      white-space: nowrap;
    }

    .directory__name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
    }

    .directory:hover {
      .directory__name {
        max-width: calc(300px - 88px);
      }
    }
  }

  &__search {
    margin-left: auto;
  }
  .sidebar .personal-space {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 12px;
    padding-bottom: 12px;
  }
}
