.navbar {
  margin-bottom: 10px;
  z-index: 1201 !important;

  a {
    text-decoration: none;
    color: inherit;
  }

  &__guide-link {
    margin-left: auto;
  }

  &__user-account {
    margin-left: auto;
  }

  &__logo-link {
    display: flex;
  }
}
