@use "../../styles/colors.scss" as colors;

.directory-permission-relation-list-item {
  border-bottom: 1px solid colors.$border-color;
  padding: 3px 0;

  display: flex;
  &__title {
    padding: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    align-self: center;
  }

  &__permissions {
    margin-left: auto;
    margin-right: 10px;
  }

  &__warning-icon {
    display: flex;
    align-items: center;
  }
}
