.copy {
  display: flex;
  width: 100%;

  &__children {
    display: flex;
    flex: 1 1 0;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
