$MARGIN_LEFT_INDENT: 15px;

.matrix-table {
  overflow: auto;
  .table .cell {
    padding: 5px;
    text-align: center;
    width: 130px;
  }

  .empty-list {
    text-align: center;
  }

  .table {
    .body .row {
      &:nth-child(n) {
        .cell:first-child {
          width: 180px;
          text-align: left;
        }
      }
    }

    .head .row .cell {
      &:first-child {
        width: 180px;
      }
    }
  }

  &__title--other-parent {
    display: flex;
  }

  &__title-icon {
    display: flex;
    align-items: center;
  }

  .matrix-table__title-indent--0 {
    margin-left: $MARGIN_LEFT_INDENT * 0;
  }
  .matrix-table__title-indent--1 {
    margin-left: $MARGIN_LEFT_INDENT * 1;
  }
  .matrix-table__title-indent--2 {
    margin-left: $MARGIN_LEFT_INDENT * 2;
  }
  .matrix-table__title-indent--3 {
    margin-left: $MARGIN_LEFT_INDENT * 3;
  }
  .matrix-table__title-indent--4 {
    margin-left: $MARGIN_LEFT_INDENT * 4;
  }
  .matrix-table__title-indent--5 {
    margin-left: $MARGIN_LEFT_INDENT * 5;
  }
}
