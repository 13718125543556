.directory-tree {
  display: flex;
  flex-direction: column;

  &__create {
    display: flex;
    margin-bottom: 8px;
    .MuiButton-root .MuiSvgIcon-root {
      font-size: 1.5rem !important;
    }
    .MuiButton-startIcon {
      margin-left: unset !important;
    }
  }

  &__create-button {
    padding: 0 !important;
  }

  &__create-button,
  .directory-create {
    margin-left: 24px !important;
  }
}
